
/**
 * speed:               (ms) Speed of open / close Animation
 * breakPoint:          (In Pixel) From this breakpoint the Accordion will stop working
 * onlyOneActive:       (boolean) Only 1 Accordion should be opened at a time
 * activeClass:         (string) String of class will be placed at active/open Accordion Element
 * waitUntilClose:      (boolean) Will only work with "onlyOneActive"-Option. Should the new active Element open after the last opened Element be closed?
 * deactiveClass:       (string) String of class will be placed at closed/inactive Accordion Element
 */

class Accordion {
    constructor (element, options) {
        this.$accordionElement = element;
        this.$parent = element.parentElement;
        this.$checkbox = this.$parent.querySelector('input[type="checkbox"]');
        this.$label = this.$parent.querySelector('label');
        this.name = element.getAttribute('data-accordion');
        this.wait = false;
        this.accordionActive = false;
        this.$accordionRoot = document.querySelector('[data-accordion="' + this.name + '-root"]');

        this.isOpen = false;

        const settings = {
            speed: 500,
            onlyOneActive: true,
            waitUntilClose: false,
            activeClass: 'is--active',
            deactiveClass: null,
            breakPointMin: null
        };

        this.settings = Object.assign({}, settings, options);

        if (!this.$accordionRoot) {
            this.$accordionRoot = this.$parent.parentElement;
        }
    }

    initialize () {
        this.accordionActive = this.checkBreakPoint();
        this.setEvents();
    }

    setEvents () {
        if (this.settings.breakPointMin !== null) {
            window.addEventListener('resize', () => {
                this.accordionActive = this.checkBreakPoint();
            });
        }

        if (this.$checkbox && this.$label) {
            this.$label.addEventListener('click', (e) => {
                if (!this.$accordionRoot.classList.contains('is--waiting')) {
                    if (this.accordionActive) {
                        if (!this.wait) {
                            if (!this.$checkbox.checked) {
                                this.closeElement(this.settings.onlyOneActive, this.$checkbox.getAttribute('id'), () => {
                                    this.openElement();
                                });
                            } else {
                                this.closeElement();
                            }
                        } else {
                            e.preventDefault();
                            e.stopPropagation();
                        }
                    }
                } else {
                    e.preventDefault();
                    e.stopPropagation();
                }
            });
        }
    }

    checkBreakPoint () {
        if (this.settings.breakPointMin !== null) {
            return window.innerWidth < this.settings.breakPointMin;
        } else {
            return true;
        }
    }

    closeElement (onlyOneActive, clickedID, callback) {
        let wait = false;

        if (onlyOneActive === true) {
            const $activeElements = [].slice.call(document.querySelectorAll('[data-accordion="' + this.name + '"]'));

            $activeElements.forEach((element) => {
                const label = element.parentElement.querySelector('label');
                const checkbox = element.parentElement.querySelector('input[type="checkbox"]');

                if (label.classList.contains(this.settings.activeClass) && checkbox.getAttribute('id') !== clickedID) {
                    if (this.settings.waitUntilClose) {
                        wait = true;
                    }
                    element.parentElement.querySelector('.' + this.settings.activeClass).click();
                }
            });

            if (typeof callback === 'function') {
                if (wait) {
                    setTimeout(() => {
                        callback();
                    }, this.settings.speed);
                } else {
                    callback();
                }
            }
        } else {
            if (this.$label.classList.contains(this.settings.activeClass)) {
                this.$accordionElement.style.height = this.$accordionElement.scrollHeight + 'px';
                this.wait = true;

                setTimeout(() => {
                    this.$accordionElement.style.height = '0px';
                    this.$label.classList.remove(this.settings.activeClass);

                    if (this.settings.deactiveClass !== null) {
                        this.$label.classList.add(this.settings.deactiveClass);
                    }
                }, 50);

                setTimeout(() => {
                    this.wait = false;
                }, this.settings.speed + 50);
            }

            if (typeof callback === 'function') {
                callback();
            }
        }
    }

    openElement () {
        this.wait = true;
        this.$accordionRoot.classList.add('is--waiting');
        this.$label.classList.add(this.settings.activeClass);

        if (this.settings.deactiveClass !== null) {
            this.$label.classList.remove(this.settings.deactiveClass);
        }

        this.$accordionElement.style.height = this.$accordionElement.scrollHeight + 'px';

        setTimeout(() => {
            this.$accordionElement.style.height = 'auto';
            this.wait = false;
            this.$accordionRoot.classList.remove('is--waiting');
        }, this.settings.speed);
    }
}

export { Accordion };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    const $faqAccordionElements = $context.querySelectorAll('[data-accordion="faq-accordion"]');
    const $historyAccordionElements = $context.querySelectorAll('[data-accordion="history-accordion"]');
    const $faqPageAccordionElements = $context.querySelectorAll('[data-accordion="faq-page-accordion"]');

    if ($faqPageAccordionElements.length > 0) {
        for (let i = 0; i < $faqPageAccordionElements.length; i++) {
            const $faqAccordion = new Accordion($faqPageAccordionElements[i], {
                speed: 500,
                onlyOneActive: false,
                waitUntilClose: false,
                activeClass: 'is--active'
            });
            $faqAccordion.initialize();
        }
    }

    if ($historyAccordionElements.length > 0) {
        for (let i = 0; i < $historyAccordionElements.length; i++) {
            const $historyAccordion = new Accordion($historyAccordionElements[i], {
                speed: 500,
                onlyOneActive: true,
                waitUntilClose: false,
                activeClass: 'is--active'
            });
            $historyAccordion.initialize();
        }
    }

    if ($faqAccordionElements.length > 0) {
        for (let i = 0; i < $faqAccordionElements.length; i++) {
            const $accordion = new Accordion($faqAccordionElements[i], {
                speed: 500,
                onlyOneActive: true,
                waitUntilClose: false,
                activeClass: 'is--active'
            });
            $accordion.initialize();
        }
    }
});
